import { AtlasInfo, AtlasPageHeader, CampusHeading } from "atlas-ds"
import Head from "next/head"
import Link from 'next/link'
import Template from "template"

export interface ErrorPageProps {
    statusCode?: 404 | 410
}

export default function ErrorPage(props: ErrorPageProps) {
    const statusCode = props.statusCode || 404
    const infos = {
        404: {
            title: "Cette page n'existe pas",
            content: "La page à laquelle vous souhaitez accéder n'est plus disponible ou son adresse est incorrecte."
        },
        410: {
            title: "Cette page n'existe plus",
            content: "Cette offre de formation n’est plus disponible."
        }
    }

    return <>
        <Head>
            <title>campusAtlas - Erreur {statusCode}</title>
        </Head>

        <Template
            pageHeader={<AtlasPageHeader>
                <CampusHeading tag="h1">Erreur {statusCode}</CampusHeading>
            </AtlasPageHeader>}
        >
            <AtlasInfo
                {...infos[statusCode]}
                type="error"
                link={<Link href="/">
                    Consulter le catalogue <i>campusAtlas</i>
                </Link>}
            />
        </Template>
    </>
}
