import * as Sentry from "@sentry/nextjs"
import { AtlasInfo, AtlasPageHeaderProps, CampusTemplate } from "atlas-ds"
import AtlasIcons from 'atlas-ds/atlas/assets/icons.svg'
import CampusIcons from 'atlas-ds/campus/assets/icons.svg'
import Env from "components/env"
import Footer from 'components/footer'
import Header from 'components/header'
import Intro from "components/intro"
import Nav from 'components/nav'
import Rgpd from 'components/rgpd'
import Search from 'components/search'
import Toasts from "components/toasts"
import { useRouter } from "next/router"
import ErrorPage from "pages/404"
import { EntrepriseContext } from 'pages/_app'
import React, { useContext } from 'react'
import { fetchAPIStats } from "services/client"
import { Entreprise } from 'services/model'
import { GONE, NOT_FOUND } from "services/server"
import useSWR from "swr"

interface TemplateProps {
    children: React.ReactNode
    pageName?: string
    serverError?: string
    statusCode?: number
    hideSearch?: boolean
    branchesAcceptees?: string[]
    showIntro?: boolean
    pageHeader?: React.ReactElement<AtlasPageHeaderProps>
    full?: boolean
}
export default function Template(props: TemplateProps) {
    const [entreprise, setEntreprise] = useContext(EntrepriseContext)
    const router = useRouter()

    const { data } = useSWR('/stats', fetchAPIStats, {
        refreshInterval: 15*60*1000,
        focusThrottleInterval: 15*60*1000,
        errorRetryInterval: 60*1000,
        fallbackData: { inscrits: null, formations: null, modules: null },
    })

    if (props.serverError === NOT_FOUND) {
        return <ErrorPage statusCode={404} />
    }
    if (props.serverError === GONE) {
        return <ErrorPage statusCode={410} />
    }

    const defineEntreprise = (entreprise: Entreprise | null) => {
        setEntreprise(entreprise)
        Sentry.setContext("entreprise", {
          name: entreprise?.name,
          siret: entreprise?.siret,
          branche: entreprise?.branche?.idBranche,
        })
    }

    const selectEntreprise = (e: Entreprise) => {
        const currentEntreprise = entreprise as Entreprise
        localStorage.setItem("currentCompany", JSON.stringify(e))
        defineEntreprise(e)
        if (!currentEntreprise || currentEntreprise.branche !== e.branche)
            router.push("/")
    }

    return <>
        <AtlasIcons />
        <CampusIcons />
        <Env />

            <CampusTemplate
                topNav={<Nav />}
                header={<Header
                    hideSearch={ props.hideSearch }
                    entreprise={entreprise}
                    selectEntreprise={selectEntreprise}
                    branchesAcceptees={ props.branchesAcceptees }
                />}
                search={!props.hideSearch ? <Search
                    pageName={props.pageName}
                    entreprise={entreprise}
                    selectEntreprise={selectEntreprise}
                    branchesAcceptees={props.branchesAcceptees}
                /> : undefined}
                intro={props.showIntro ? <Intro modulesCount={data?.modules}/> : undefined}
                pageHeader={props.pageHeader}
                footer={<Footer stats={data} />}
                rgpd={<Rgpd />}
                full={props.full}
                toasts={<Toasts />}
            >
                { props.serverError
                    ? <AtlasInfo type="error" title="Erreur">
                        Une erreur s'est produite. Veuillez réessayer plus tard.
                    </AtlasInfo>
                    : props.children
                }
            </CampusTemplate>
        </>
}
