import { AtlasFieldText, AtlasSearchDropdown, CampusCompanySearch } from 'atlas-ds';
import { useEffect, useState } from 'react';
import { useAPIRechercheEntreprises } from 'services/client';
import useDebounce from 'ui/useDebounce';
import CompaniesList from './companies-list';
import { linkConstructor } from './link';

interface CompanySearchProps {
    select: (entreprise: any) => void
}

export default function CompanySearch(props: CompanySearchProps) {

    const [ recherche, setRecherche, debounced, rechercheDebounced ] = useDebounce('', 750)
    const [ wait, setWait ] = useState(true)

    const { data: rechercheEntreprises, error: rechercheEntreprisesError, mutate: rechercheEntreprisesMutate }
        = useAPIRechercheEntreprises(wait, rechercheDebounced)

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRecherche(event.target.value)
    }

    useEffect(() => {
        setWait(!(recherche && recherche.length >= 3))
        rechercheEntreprisesMutate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounced])

    return <CampusCompanySearch
        catalogueHref='/branches'
        linkConstructor={linkConstructor}
        searchDropdown={<AtlasSearchDropdown
            input={<AtlasFieldText
                name="company-search"
                label="Rechercher une entreprise"
                instructions="3 caractères minimum"
                type="search"
                placeholder="Raison sociale, numéro SIREN"
                autoComplete="off"
                spellcheck={false}
                hideRequiredHint
                required
                value={recherche}
                minLength={3}
                onChange={onChange}
            />}
            resultsCount={ rechercheEntreprises?.length }
            loading={ recherche?.length >= 3 && !rechercheEntreprises && !rechercheEntreprisesError }
            error={ rechercheEntreprisesError }
        >
            <CompaniesList
                entreprises={rechercheEntreprises || []}
                onSelect={ props.select }
            />
        </AtlasSearchDropdown>}
    />
}
