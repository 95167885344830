import { useCallback, useRef, useState } from 'react'

export default function useDebounce<T>(defaultValue: T, delay = 750): [T, (v: T) => void, number, T, (v: T) => void] {
    const [value, setValueImmediately] = useState(defaultValue)
    const [finalValue, setFinalValue] = useState(defaultValue)
    const [debounced, setDebounced] = useState(Date.now())
    const timeout = useRef<NodeJS.Timeout | null>(null)

    const setValue = useCallback((value: any) => {
        setValueImmediately(value)
        if (timeout.current !== null) {
            clearTimeout(timeout.current)
        }
        timeout.current = setTimeout(() => {
            setDebounced(Date.now())
            setFinalValue(value)
        }, delay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const forceValue = (value: T) => {
        setValueImmediately(value)
        setFinalValue(value)
    }

    return [value, setValue, debounced, finalValue, forceValue]
}
