import * as Sentry from "@sentry/nextjs"
import { AtlasLinkConstructor, CampusHeader } from 'atlas-ds'
import CompaniesList from "components/companies-list"
import { useSession } from "next-auth/react"
import Link from "next/link"
import { useRouter } from "next/router"
import { EntrepriseContext } from "pages/_app"
import { useContext, useEffect, useRef } from "react"
import { useAPIProfil } from "services/client"
import { Entreprise } from "services/model"

interface HeaderProps {
  branchesAcceptees?: string[];
  hideSearch?: boolean;
  entreprise: Entreprise
  selectEntreprise: (entreprise: any) => void
}

export default function Header(props: HeaderProps) {
  const [entreprise, setEntreprise] = useContext(EntrepriseContext)
  const { data: session } = useSession()
  const isUser = !!session?.user
  const router = useRouter()

  const { data: profil } = useAPIProfil()
  const entreprises = profil?.entreprises

  const defineEntreprise = (entreprise: Entreprise | null) => {
    setEntreprise(entreprise);
    Sentry.setContext("entreprise", {
      name: entreprise?.name,
      siret: entreprise?.siret,
      branche: entreprise?.branche?.idBranche,
    })
  }

  useEffect(() => {
    if (!router.isReady) return

    const currEnt = localStorage.getItem("currentCompany") ?? null
    const ent = currEnt ? JSON.parse(currEnt) : null

    let found = false
    const routerEntreprise = router.query.entreprise
    if (isUser && session && routerEntreprise) {
      if (ent && routerEntreprise === ent.siret) {
        found = true;
      } else if (entreprises) {
        const e = entreprises.find((e) => e.siret === routerEntreprise);
        if (e) {
          localStorage.setItem("currentCompany", JSON.stringify(e))
          defineEntreprise(e)
          found = true
        }
      }
    }
    if (!found) {
      const currEnt = localStorage.getItem("currentCompany") ?? null
      if (currEnt) {
        const ent = JSON.parse(currEnt);
        if (
          isUser &&
          session &&
          entreprises &&
          entreprises.length > 0 &&
          !entreprises.find((e) => e.id === ent.id)
        ) {
          const e = entreprises[0];
          localStorage.setItem("currentCompany", JSON.stringify(e));
          defineEntreprise(e);
        } else {
          defineEntreprise(ent);
        }
      } else if (
        isUser &&
        session &&
        session.accessToken &&
        entreprises &&
        entreprises.length > 0
      ) {
        const e = entreprises[0];
        localStorage.setItem("currentCompany", JSON.stringify(e))
        defineEntreprise(e)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady, isUser, entreprises])

  const detailsRef = useRef<HTMLDetailsElement>(null)

  const removeEntreprise = () => {
    localStorage.removeItem("currentCompany")
    defineEntreprise(null)
  }

  function closeDetails() {
    if (detailsRef.current) detailsRef.current.open = false
  }

  const selectCompany = (entreprise: Entreprise) => {
    props.selectEntreprise(entreprise)
    closeDetails()
  }

  const linkConstructor: AtlasLinkConstructor = (
    props: any,
    content: React.ReactNode
  ): JSX.Element => (
    (<Link
      {...props}
      aria-current={router.route === "/" ? "page" : undefined}>

      {content}

    </Link>)
  )

  return <CampusHeader
    ref={detailsRef}
    linkConstructor={linkConstructor}
    currentCompany={entreprise?.name}
    onCompanyClear={entreprise && (entreprises ?? []).length === 0 ? removeEntreprise : undefined}
    companiesList={
      entreprises && entreprises.length > 1 ? (
        <CompaniesList
            onSelect={ selectCompany }
            entreprises={ entreprises }
        ></CompaniesList>
      ) : undefined
    }
  />
}
