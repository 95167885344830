import { AtlasOptions, AtlasValue, AtlasValues } from 'atlas-ds';
import { Entreprise } from 'services/model';

interface CompaniesListProps {
    onSelect: (entreprise: any) => void
    entreprises: Entreprise[];
}

export default function CompaniesList(props: CompaniesListProps) {
    return <AtlasOptions
        options={props.entreprises.map(entreprise => ({
            id: entreprise.id,
            onClick: () => props.onSelect(entreprise),
            content: <AtlasValues label={ entreprise.name }>
                <AtlasValues.Grid>
                    <AtlasValue label="SIREN">
                        { entreprise.siren ? entreprise.siren : entreprise.siret?.substring(0, 9) }
                    </AtlasValue>
                    { entreprise.branche && <AtlasValue label="Branche">
                        { entreprise.branche.titre }
                    </AtlasValue> }
                </AtlasValues.Grid>
                { entreprise.adresse.trim()
                    ? <AtlasValue label="Adresse">{ entreprise.adresse }</AtlasValue>: <></>
                }
            </AtlasValues>
        })) || []}
        loader={<AtlasValues>
            <AtlasValues.Grid>
                <AtlasValue label="SIREN"> </AtlasValue>
                <AtlasValue label="Branche"> </AtlasValue>
            </AtlasValues.Grid>
            <AtlasValue label="Adresse"> </AtlasValue>
        </AtlasValues>}
    />
}
