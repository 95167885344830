import { AtlasInfo, CampusSearch, CampusSearchTheme } from 'atlas-ds';
import CompanySearch from "components/company-search";
import ModuleSearch from "components/module-search";
import { EntrepriseContext } from "pages/_app";
import { useContext } from "react";
import { Entreprise } from "services/model";

interface SearchProps {
    entreprise: Entreprise
    selectEntreprise: (entreprise: any) => void
    pageName?: string
    branchesAcceptees?: string[]
}

export default function Search(props: SearchProps) {
    const [entreprise] = useContext(EntrepriseContext);

    const erreurNonDispo = props.branchesAcceptees
        && entreprise?.branche
        && !props.branchesAcceptees.includes(entreprise?.branche?.idBranche)

    return <CampusSearch
        ariaLabel={ entreprise ? 'Recherche formation' : 'Recherche entreprise'}
        searchType={entreprise ? "module" : "company"}
        theme={props.pageName as CampusSearchTheme}
        info={ erreurNonDispo ? <AtlasInfo title="Contenu non disponible" type="error">
            Attention : vous consultez un catalogue de formations dont vous ne pourrez pas bénéficier. Afin de consulter un catalogue personnalisé, vous pouvez directement faire une recherche.
        </AtlasInfo> : undefined }
    >
    {entreprise
        ? <ModuleSearch
                branche={ entreprise.branche }
                erreurNonDispo={ erreurNonDispo }
            />
        : <CompanySearch select={ props.selectEntreprise } />
    }
    </CampusSearch>
}
