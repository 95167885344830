import { AtlasFieldText, AtlasSearchDropdown, CampusCardTag, CampusModuleSearch } from 'atlas-ds';
import { linkConstructor } from 'components/link';
import { useSession } from 'next-auth/react';
import { useEffect, useRef, useState } from 'react';
import { login, useAPIRechercheModules } from 'services/client';
import { Branche } from 'services/model';
import useDebounce from 'ui/useDebounce';

interface ModuleSearchProps {
    branche?: Branche
    erreurNonDispo?: boolean
}

export default function ModuleSearch(props: ModuleSearchProps) {

    const { data: authSession } = useSession()
    const [ recherche, setRecherche, debounced, rechercheDebounced ] = useDebounce('', 750)
    const [ wait, setWait ] = useState(true)
    const { data: rechercheModules, error: rechercheModulesError, mutate: rechercheModulesMutate }
        = useAPIRechercheModules(wait, rechercheDebounced)
    const input = useRef<HTMLInputElement>();

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRecherche(event.target.value)
    }

    useEffect(() => {
        setWait(!(recherche && recherche.length >= 3))
        rechercheModulesMutate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounced])

    // Count the total of thematiques + modules
    const resultsCount = rechercheModules?.reduce((totalCount, thematique) =>
        totalCount + (thematique.modules?.length || 0) + 1, // +1 for thematique
    0);

    const thematiques = rechercheModules?.map(thematique => ({
        id: thematique.id,
        href: `/thematiques/${thematique.path}/${thematique.id}`,
        label: thematique.titre,
        modules: thematique.modules?.map(module => ({
            id: module.id,
            href: `/modules/${module.path}/${module.id}`,
            label: module.titre,
            ...thematique.fne ? {
                tag: <CampusCardTag>FNE</CampusCardTag>
            } : {}
        })) || [],
        ...thematique.fne ? {
            tag: <CampusCardTag>FNE</CampusCardTag>
        } : {}
    })) || []

    return (<>
        <CampusModuleSearch
            catalogueHref={props.branche ? `/branches/${props.branche.path}/${props.branche.id}` : '/branches'}
            loginLink={authSession ? undefined : <>
                Pour la demande de prise en charge, il est important de vous&nbsp;
                <a href="#" rel="noopener noreferrer" onClick={() => login("login")}>
                  connecter à myAtlas
                </a>
            </>}
            linkConstructor={linkConstructor}
            searchDropdown={<AtlasSearchDropdown
                input={<AtlasFieldText
                    ref={input}
                    type="search"
                    name="module-search"
                    label="Rechercher un module"
                    instructions="3 caractères minimum"
                    placeholder="Thématique, module de formation"
                    autoComplete="off"
                    spellcheck={false}
                    required
                    value={recherche}
                    minLength={3}
                    onChange={onChange}
                    hideRequiredHint
                />}
                resultsCount={ resultsCount }
                error={ rechercheModulesError }
                loading={ recherche?.length >= 3 && !rechercheModules && !rechercheModulesError }
            >
                <CampusModuleSearch.Results
                    thematiques={thematiques}
                    linkConstructor={linkConstructor}
                    onClick={() => {
                        setRecherche('')
                        if (input.current) {
                            input.current.value = "";
                        }
                    }}
                />
            </AtlasSearchDropdown>}
        />
    </>)
}
