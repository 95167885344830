"use client";

import { AtlasEnv, AtlasEnvProps } from "atlas-ds";
import { useEffect, useState } from "react";

export default function Env() {
    const [href, setHref] = useState("");

    useEffect(() => {
        if (typeof window !== "undefined" && process.env.NEXT_PUBLIC_ENV_SWITCHER) {
            setHref(
                window.location
                  .toString()
                  .replace(window.location.origin, process.env.NEXT_PUBLIC_ENV_SWITCHER)
            );
        }
    }, []);

    if (!process.env.NEXT_PUBLIC_ENV) return null;

    return (
        <AtlasEnv
            env={process.env.NEXT_PUBLIC_ENV as AtlasEnvProps["env"]}
            href={href}
        />
    );
}
