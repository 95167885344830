import { CampusFooter } from 'atlas-ds';
import { linkConstructor } from 'components/link';

interface FooterProps {
    stats?: {
        inscrits: number;
        formations: number;
    }
}

export default function Footer(props: FooterProps) {
    return <CampusFooter
        contactLink={{
            href: "https://www.opco-atlas.fr/contact.html",
            children: "opco-atlas.fr"
        }}
        stats={[
            ...props.stats?.inscrits ? [{
                label: "Inscrits",
                value: props.stats.inscrits
            }] : [],
            ...props.stats?.formations ? [{
                label: "Formations",
                value: props.stats.formations
            }] : []
        ]}
        primaryLinks={[
            {
                children: <>C'est quoi&nbsp;<i>campusAtlas</i> ?</>,
                href: "/landing"
            },
            {
                children: `Nos branches`,
                href: "/branches"
            }
        ]}
        secondaryLinks={[
            {
                children: "CGU",
                href: "/cgu"
            },
            {
                children: "Mentions légales",
                href: "https://www.opco-atlas.fr/mentions-legales.html"
            },
            {
                children: "Protection des données personnelles",
                href: "https://www.opco-atlas.fr/rgpd.html"
            },
            {
                children: "Cookies",
                href: "https://www.opco-atlas.fr/cookies.html"
            }
        ]}
        socialLinks={[
            {
                title: "Page LinkedIn d'Opco Atlas",
                href: "https://www.linkedin.com/company/opco-atlas",
                icon: "linkedin"
            },
            {
                title: "Page X d'Opco Atlas",
                href: "https://x.com/OpcoAtlas",
                icon: "x"
            },
            {
                title: "Page Youtube d'Opco Atlas",
                href: "https://www.youtube.com/c/OpcoAtlas",
                icon: "youtube"
            },
            {
                title: "Page Instagram d'Orientation Atlas",
                href: "https://www.instagram.com/orientationatlas",
                icon: "instagram"
            },
            {
                title: "Compte Tiktok d'Orientation Atlas",
                href: "https://www.tiktok.com/@orientationatlas",
                icon: "tiktok"
            },
            {
                title: "Page Pinteret d'Orientation Atlas",
                href: "https://www.pinterest.fr/orientationAtlas",
                icon: "pinterest"
            },
        ]}
        accessibilityLinks={[
            {
                children: "Plan du site",
                href: "/plan-du-site"
            },
            {
                children: "Accessibilité : partiellement conforme",
                href: "/accessibilite"
            }
        ]}
        linkConstructor={linkConstructor}
    />
}
