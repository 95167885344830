import { AtlasRgpd } from 'atlas-ds';
import { useEffect, useState } from 'react';

export default function Rgpd() {
    const [show, setShow] = useState(false);

    const onClose = () => {
        localStorage.setItem('rgpdSeen', 'true');
    };

    useEffect(() => {
        setShow(localStorage.getItem('rgpdSeen') !== 'true');
    }, []);

    if (!show) {
        return <></>;
    }

    return <AtlasRgpd
        ariaLabel="Information RGPD"
        onClose={ onClose }
        link={{
            href: "https://www.opco-atlas.fr/cookies.html",
            label: "En savoir plus",
            target: "_blank",
            ariaLabel: "Information sur les cookies"
        }}
    >Le site <i>campusAtlas</i> utilise l'analyse de données anonymisées pour améliorer l'expérience utilisateur.</AtlasRgpd>
}
